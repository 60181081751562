import { Modal } from 'ant-design-vue'

export function errorHandler(error: string) {
  console.log('errorHandler', error)
  Modal.warning({
    title: () => '提醒',
    content: error + '，稍后自动重启',
    centered: true,
    footer: ''
  })
  setTimeout(() => {
    window.location.reload()
  }, 5000)
}
