import myLogger from './aliUtils/Logger'

export class AudioPlayer {
  public audioElement: myHTMLAudioElement | null = null
  private audioData: number[] = []
  private audioDataBackUp: number[] = []
  private sourceBuffer: SourceBuffer | undefined = undefined
  private sourceBufferAppending = false
  private respComplete = false

  private onednTimer: NodeJS.Timeout | null = null
  private oneded: boolean = false
  constructor(speakerDevice?: string) {
    this.audioInit(speakerDevice)
  }
  // 音频初始化
  private audioInit(speakerDevice?: string) {
    this.audioElement = document.createElement('audio') as myHTMLAudioElement
    this.audioElement.setAttribute('controls', '')
    this.audioElement.style.position = 'fixed'
    this.audioElement.style.top = '-2px'
    this.audioElement.style.height = '1px'
    this.audioElement.style.width = '1px'
    this.audioElement.style.zIndex = '2'
    // 将audio元素添加到页面中
    document.body.appendChild(this.audioElement)
    // 检查是否支持设置音频输出设备
    // 设置音频输出设备
    speakerDevice &&
      this.audioElement.setSinkId &&
      this.audioElement
        .setSinkId(speakerDevice)
        .then(() => {
          console.log('音频输出设备设置成功,', speakerDevice)
        })
        .catch((error) => {
          console.error('无法设置音频输出设备:', error)
        })

    const mediaSource = new MediaSource()

    this.audioElement.src = URL.createObjectURL(mediaSource)
    this.audioElement.volume = 1.0

    mediaSource.addEventListener('sourceopen', () => {
      this.sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg')
      // 启动定时器检查
      this.checkPlaybackComplete()
      // console.log("setData appendBuffer: sourceopen");

      // 监听`SourceBuffer`对象的`updateend`事件
      this.sourceBuffer.addEventListener('updateend', () => {
        // .log("setData appendBuffer: updateend");
        this.sourceBufferAppending = false
        if (this.audioData.length > 0) {
          // !streamRecorder.audioStream &&
          //   streamRecorder.setAudioTrack(this.audioElement!.captureStream());
          // 假设您从后端接收到的二进制数据存在变量data中
          this.sourceBuffer?.appendBuffer(new Uint8Array(this.audioData))
          this.sourceBufferAppending = true
          this.audioData = []
        }
      })
    })

    this.audioElement.onwaiting = () => {
      myLogger.log('audio_play_end', '语音暂停')
      // 播放完的时机：所有服务端返回的数据都播放完毕，为了防止服务端返回数据不完整，这里延迟一秒，多等待一秒
      this.oneded = true

      // 未开始的暂停，不处理
      if (this.audioElement!.currentTime < 1) {
        return
      }
      if (this.onednTimer) {
        clearTimeout(this.onednTimer)
        this.onednTimer = null
      }

      const isFinished = this.isFinishedPlayCurrentAllData()

      if (this.respComplete && this.audioData.length === 0 && isFinished) {
        // if (this.sourceBuffer) {
        //   try {
        //     // 假设你想删除所有已经播放过的数据
        //     let currentTime = this.audioElement!.currentTime
        //     currentTime = currentTime - 70
        //     currentTime > 0 && this.sourceBuffer.remove(0, currentTime)
        //   } catch (error) {
        //     myLogger.warn('remove_buffer_error', '移除已经播放的数据失败')
        //   }
        // }
        this._onPlayEnd && this._onPlayEnd()
        myLogger.log('audio_play_end', '语音播放结束')
      } else if (this._onPlayEnd) {
        this.onednTimer = setTimeout(() => {
          myLogger.log('audio_play_end', '语音播放超时结束')
          // if (this.sourceBuffer) {
          //   try {
          //     // 假设你想删除所有已经播放过的数据
          //     let currentTime = this.audioElement!.currentTime
          //     currentTime = currentTime - 70
          //     currentTime > 0 && this.sourceBuffer.remove(0, currentTime)
          //   } catch (error) {
          //     myLogger.warn('remove_buffer_error', '移除已经播放的数据失败')
          //   }
          // }
          this._onPlayEnd && this.oneded && this._onPlayEnd()
        }, 3500)
      }
    }

    this.audioElement.oncanplay = () => {
      // 如果重新播放，要去掉之前超时的定时器
      if (this.onednTimer) {
        clearTimeout(this.onednTimer)
        this.onednTimer = null
      }

      // 状态没有变化，不通知
      if (!this.oneded) {
        return
      }
      this.audioElement?.play()
      this.oneded = false
      this._onPlayStart && this._onPlayStart()
      myLogger.log('audio_play', '语音可以播放')
    }
    this.audioElement.play()
  }

  public audioUninit() {
    this.audioElement?.pause()
    this.audioElement?.remove()
    this.audioElement = null
    this.audioData = []
    this.audioDataBackUp = []
    this.sourceBuffer = undefined
    this.sourceBufferAppending = false
    this.respComplete = false
  }

  // 音频播放
  private audioPlay() {
    // 如果重新播放，要去掉之前超时的定时器
    if (this.onednTimer) {
      clearTimeout(this.onednTimer)
      this.onednTimer = null
    }
    this.audioElement?.play()
    this.oneded = false
    this._onPlayStart && this._onPlayStart()
  }

  private prePlayedTime = 0
  private checkPlaybackComplete() {
    if (this.sourceBuffer && this.audioElement) {
      const currentTime = this.audioElement.currentTime
      // 停止播放,且存在未播放的数据，未播放数据大于1s
      if (currentTime === this.prePlayedTime && this.sourceBuffer.buffered.length > 0) {
        // 判断是否有未播放完的数据，有就回退一点继续播放完成
        const buffered = this.sourceBuffer.buffered
        const end = buffered.end(buffered.length - 1)
        const bufferedNotPlay = end - currentTime > 1
        if (bufferedNotPlay) {
          myLogger.log('audio_play', '强制播放')
          this.audioElement.currentTime = currentTime - 0.1
          this.audioPlay()
        }
      }
      this.prePlayedTime = this.audioElement.currentTime
      setTimeout(() => {
        this.checkPlaybackComplete()
      }, 1000)
    }
  }

  isFinishedPlayCurrentAllData(): boolean {
    const currentTime = this.audioElement?.currentTime
    if (!currentTime) return true

    const buffered = this.sourceBuffer?.buffered
    const end = buffered!.end(buffered!.length - 1)
    return end - currentTime < 0.5
  }

  public setData(arr: { data: Array<number> }, respComplete?: boolean) {
    this.respComplete = !!respComplete
    // 防止最后的完成动作没有数据
    if (arr && arr.data) {
      const audioArray = arr.data
      audioArray && this.audioData.push(...audioArray)
      if (!this.sourceBufferAppending && this.audioData.length > 0) {
        this.sourceBuffer?.appendBuffer(new Uint8Array(this.audioData))
        this.sourceBufferAppending = true
        this.audioData = []
        // !streamRecorder.audioStream &&
        //   streamRecorder.setAudioTrack(this.audioElement!.captureStream());
      }
    }
  }

  public _onPlayEnd?: () => void
  public onPlayEnd(callBack: () => void) {
    this._onPlayEnd = callBack
  }
  private _onPlayStart?: () => void
  public onPlayStart(callBack: () => void) {
    this._onPlayStart = callBack
  }

  private _onPlayPause?: () => void
  public onPlayPause(callBack: () => void) {
    this._onPlayPause = callBack
  }

  public rePlay() {
    this.audioData = this.audioDataBackUp
    myLogger.log('audio_play', 'rePlay')
    this.audioPlay()
  }
}
