import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import myLogger from './assets/utils/aliUtils/Logger'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#app')

// 全局错误监控
window.addEventListener(
  'error',
  (
    event: ErrorEvent & {
      filename?: string
      lineno?: number
      colno?: number
    }
  ) => {
    if (event.error) {
      // 如果 event 对象有 error 属性，表明这是一个 JavaScript 错误
      myLogger.error('JavaScript error caught:', event.error.message + ',' + event.error.stack)
    } else {
      // 否则，这可能是一个资源加载错误
      myLogger.error(
        'Resource load error caught:',
        event.filename + ',' + event.lineno + ',' + event.colno
      )
    }
    // 阻止错误继续传播到 window.onerror
    event.preventDefault()
  },
  true
)

declare global {
  interface Window {
    onWujiStreamVolumeChange: (volume: number) => void
    onWujiAliTextResponse: (text: string, isEnd: number) => void
    onWujiVolumeChange: (isSpeaking: 'speaking' | 'ended') => void
    onVideoPlayEnd: (err?: string) => void
    onStreamChanged: (type: 'ADD' | 'DELETE', streamID: string) => void
    Android: {
      toggleRecorder(pause: boolean): unknown
      setWujiAppCount: (count: string, startUpTime: string) => void
      closeApp: () => void
      initASR: (appkey: string, token: string, streamid: string, maxSilence: number) => void
      stopRecorder(): void
      prepareVideo(url: string): void
      playVideo(url: string): void
      startRealTimeTalk(): void
      stopRealTimeTalk(): void
    }
  }
}
