import { userInfoUtil } from './UserInfo'
import { RetryStrategy } from './retryUtil'
import { errorHandler } from './errorUtil'
import myLogger from './aliUtils/Logger'
// 定义心跳包内容
let heartbeatTimer: NodeJS.Timeout | undefined

// 定义心跳间隔时间（单位：毫秒）
const heartbeatInterval = 30000 // 每隔30秒发送一次心跳包

class MyAISocket {
  seq = 0
  public socket?: WebSocket
  private waitingEventMap: { [index: string]: any } = {}
  private retryStrategy
  private reTryConnectTimer: NodeJS.Timeout | null = null
  private wsParam: { [index: string]: string } = {}
  constructor() {
    // 初始化重试策略，添加重试执行器
    this.retryStrategy = new RetryStrategy(() => {
      this.connectWS()
    })
    // 所有重试执行完，最后失败后的回调
    this.retryStrategy.onFinish(() => {
      // 回调所有等待事件，但是没有任何返回参数
      for (const key in this.waitingEventMap) {
        this.waitingEventMap[key].callBack()
      }
      this.waitingEventMap = {}
      myLogger.error('MyAISocket_reconnect', 'AI服务异常：超过最大重试次数')
      errorHandler('AI服务异常：超过最大重试次数')
    })
  }

  public setParam(key: string, value: string) {
    this.wsParam[key] = value
  }
  public cancelRequeste() {
    this.waitingEventMap = {}
  }

  public reset() {
    // 清除上次连接的所有事件
    if (this.socket) {
      this.socket.onclose = null
      this.socket.onerror = null
      this.socket.onmessage = null
      this.socket.onopen = null
      this.socket.close()
      this.socket = undefined
    }
    // 清除定时器
    heartbeatTimer && clearInterval(heartbeatTimer as NodeJS.Timeout)
    this.waitingEventMap = {}
  }

  private connectWS(msg?: string) {
    this.reTryConnectTimer = setTimeout(() => {
      this.retryStrategy.excute()
    }, 3000)

    // 清除上次连接的所有事件
    if (this.socket) {
      this.socket.onclose = null
      this.socket.onerror = null
      this.socket.onmessage = null
      this.socket.onopen = null
      this.socket = undefined
    }

    // 拼接参数
    const params: string[] = []
    for (const key in this.wsParam) {
      params.push(`${key}=${this.wsParam[key]}`)
    }
    // 创建 WebSocket 连接
    this.socket = new WebSocket(
      import.meta.env.VITE_APP_API_URL + '?token=' + userInfoUtil.token + '&' + params.join('&')
    )

    // 监听连接成功事件
    this.socket.onopen = () => {
      this.reTryConnectTimer && clearTimeout(this.reTryConnectTimer)
      this.reTryConnectTimer = null
      this.retryStrategy.reset()

      console.log('WebSocket 连接已建立')
      msg && this.socket?.send(msg)

      // 设置定时器，定期发送心跳包
      heartbeatTimer = setInterval(() => {
        this.socket?.send(JSON.stringify({ cmd: 'HB', seq: ++this.seq }))
      }, heartbeatInterval)
    }

    // 监听接收消息事件
    this.socket.onmessage = (event) => {
      const result = JSON.parse(event.data)
      // console.log('onmessage waitingEventMap', this.waitingEventMap)
      const callBackEvent = this.waitingEventMap[result.cmd + '_' + result.seq]
      const callBack = callBackEvent && callBackEvent.callBack
      if (result.cmd != 'ERROR' && result.cmd != 'HB' && !callBack) {
        console.warn('收到非本地请求！', result)
      } else {
        switch (result.cmd) {
          case 'ASK_AI':
            this.askAICallBack(result, callBack)
            break
          case 'CANCEL_ASK_AI':
            callBack()
            break
          case 'TTV':
            this.TTVCallBack(result, callBack)
            break
          case 'ERROR':
            myLogger.error('server_error', JSON.stringify(result.error))
            errorHandler('AI服务异常，' + result.error)
            break
        }
      }

      // console.log('after onmessage waitingEventMap', this.waitingEventMap)
    }

    // 监听连接关闭事件
    this.socket.onclose = (e) => {
      console.log('WebSocket 连接已关闭')
      if (e.code === 1006) {
        this.retryStrategy.excute()
      }

      // 清除定时器
      heartbeatTimer && clearInterval(heartbeatTimer as NodeJS.Timeout)
    }

    // 监听连接关闭事件
    this.socket.onerror = (error) => {
      myLogger.error('server_error', '连接错误:连接错误' + JSON.stringify(error))
      this.socket = undefined
    }
  }

  public askAI(
    question: string,
    callBack: (
      data: any,
      media: {
        url: string
        type: 'mv' | 'img'
        afterContent: string
      },
      complete?: boolean,
      aiResponse?: string
    ) => void
  ) {
    // console.warn('askAi waitingEventMap:', JSON.stringify(this.waitingEventMap))
    const keys = Object.keys(this.waitingEventMap)
    const preKeys = keys.filter((key) => key.startsWith('ASK_AI'))
    if (preKeys.length > 0) {
      console.warn('askAi ignored for repeat:', JSON.stringify(this.waitingEventMap))
      return -1
    }

    const body = {
      cmd: 'ASK_AI',
      seq: ++this.seq,
      params: {
        question
      }
    }

    this.waitingEventMap[body.cmd + '_' + body.seq] = {
      addTime: Date.now(),
      callBack
    }
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.connectWS(JSON.stringify(body))
    } else {
      this.socket.send(JSON.stringify(body))
    }
    return body.seq
  }

  public cancelAskAI(seq: number) {
    console.warn('取消上一次的请求，重新提问！')
    const body = {
      cmd: 'CANCEL_ASK_AI',
      seq: ++this.seq,
      params: {}
    }
    this.waitingEventMap[body.cmd + '_' + body.seq] = {
      addTime: Date.now(),
      callBack: () => {
        delete this.waitingEventMap['ASK_AI_' + seq]
        delete this.waitingEventMap['CANCEL_ASK_AI' + body.seq]
      }
    }
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.connectWS(JSON.stringify(body))
    } else {
      this.socket.send(JSON.stringify(body))
    }
  }
  private askAICallBack(
    result: {
      seq: string
      cmd: string
      aiResponse: string
      media: {
        url: string
        type: 'mv' | 'img'
        afterContent: string
      }
      data: any
      complete: boolean
    },
    callBack: (
      data: any,
      media: {
        url: string
        type: 'mv' | 'img'
        afterContent: string
      },
      complete?: boolean,
      aiResponse?: string
    ) => void
  ) {
    // 单次请求返回数据，可能会追加，因此直到下次请求回来之前，都不能删除回调，防止追加内容不能播放
    // ?? 追加的情况是什么？ 加入心跳后，删除上一个已经不对，因为seq不一致
    if (result.complete) {
      delete this.waitingEventMap[result.cmd + '_' + result.seq]
    }
    callBack(result.data, result.media, result.complete, result.aiResponse)
  }

  public TTV(question: string, callBack: (data: any, complete?: boolean) => void) {
    const keys = Object.keys(this.waitingEventMap)
    const preKeys = keys.filter((key) => key.startsWith('TTV'))
    if (preKeys.length > 1) {
      console.warn('TTV', JSON.stringify(this.waitingEventMap))
    }
    const body = {
      cmd: 'TTV',
      seq: ++this.seq,
      params: {
        question
      }
    }
    this.waitingEventMap[body.cmd + '_' + body.seq] = {
      addTime: Date.now(),
      callBack
    }
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.connectWS(JSON.stringify(body))
    } else {
      this.socket.send(JSON.stringify(body))
    }
  }
  private TTVCallBack(
    result: {
      seq: string
      cmd: string
      question: string
      data: any
      complete: boolean
    },
    callBack: (data: any, complete?: boolean) => void
  ) {
    callBack(result.data, result.complete)
    if (result.complete) {
      delete this.waitingEventMap[result.cmd + '_' + result.seq]
    }
  }
  public forceClearTimeOutResponse(action: string): void {
    const keysArray = Object.keys(this.waitingEventMap)
    keysArray.forEach((key) => {
      if (key.startsWith(action)) {
        delete this.waitingEventMap[key]
        myLogger.error('server_error', '强制清除超时请求:' + key)
      }
    })
  }
}

const myAISocket = new MyAISocket()
export default myAISocket
