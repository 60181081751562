import type { VoceToTextBaseUtils } from '../bean'
import { errorHandler } from '../errorUtil'
import { RetryStrategy } from '../retryUtil'

const APPID = 'd2919785'
const API_KEY = '86e57144f42c988d8399c23277f02b8f'
let volumeTimer: NodeJS.Timeout | null = null
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let reTryConnectTimer: NodeJS.Timeout | null = null
export class VoceToTextWebUtils implements VoceToTextBaseUtils {
  private iatWS?: WebSocket
  private recorder: RecorderManager | null = null
  private stream: MediaStream | null = null
  resultText: string[] = []
  private translateState: 'doing' | 'end' = 'end'
  private _onTextResonse?: (text: string, state: 'doing' | 'end', closed?: boolean) => void
  private _isSpeaking: 'ended' | 'speaking' = 'ended'
  retryStrategy: RetryStrategy

  private get isSpeaking() {
    return this._isSpeaking
  }
  private set isSpeaking(state: 'ended' | 'speaking') {
    this._isSpeaking = state
    this._speakStateCallBack && this._speakStateCallBack(state)
  }

  constructor(micID?: string) {
    this.initRecorder(micID)
    // 初始化重试策略，添加重试执行器
    this.retryStrategy = new RetryStrategy(() => {
      this.connectWebSocket()
    })
    // 所有重试执行完，最后失败后的回调
    this.retryStrategy.onFinish(() => {
      // 回调所有等待事件，但是没有任何返回参数
      errorHandler('语音识别失败')
    })
  }

  private initRecorder(micID?: string) {
    this.recorder = new RecorderManager('', micID)
    this.recorder.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
      if (this.iatWS?.readyState === this.iatWS?.OPEN) {
        // console.log('onFrameRecorded', this.iatWS?.readyState, this.iatWS?.OPEN, this._paused)
        if (this._paused) {
          this.iatWS?.send(new Int8Array(new Int8Array(frameBuffer).length))
        } else {
          this.iatWS?.send(new Int8Array(frameBuffer))
        }

        if (isLastFrame) {
          this.iatWS?.send('{"end": true}')
        }
      }
    }
    this.recorder.onStop = () => {
      console.log('recorder onStop')
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.onWujiStreamVolumeChange = (volume: number) => {
      if (this._paused) {
        return
      }
      if (volume > 0.01) {
        if (volumeTimer) {
          clearTimeout(volumeTimer)
          volumeTimer = null
        }
        this.isSpeaking = 'speaking'
      } else if (volume <= 0.01) {
        if (!volumeTimer) {
          volumeTimer = setTimeout(() => {
            this.isSpeaking = 'ended'
            // 停止说话后，且已经有完整识别结果时，发送识别结果
            // if (
            //   this.resultText.length > 0 &&
            //   this._onTextResonse &&
            //   this.translateState === "end"
            // ) {
            //   this._onTextResonse(this.resultText.join(""));
            //   this.resultText = [];
            // }
          }, 0.2 * 1000)
        }
      }
    }

    this.onWujiStreamCompleted((m: MediaStream) => {
      console.log('onWujiStreamCompleted', m)
    })
    // 开始录音
    this.recorder?.start({
      sampleRate: 16000,
      frameSize: 1280
    })
  }

  private getWebSocketUrl() {
    // 请求地址根据语种不同变化
    const url = 'wss://rtasr.xfyun.cn/v1/ws'
    const appId = APPID
    const secretKey = API_KEY
    const ts = Math.floor(new Date().getTime() / 1000)
    const signa = hex_md5(appId + ts)
    const signatureSha = CryptoJSNew.HmacSHA1(signa, secretKey)
    let signature = CryptoJS.enc.Base64.stringify(signatureSha)
    signature = encodeURIComponent(signature)
    return `${url}?appid=${appId}&ts=${ts}&signa=${signature}&vadMdn=4&pd=life&engLangType=4`
  }

  renderResult(resultData: string) {
    const jsonData = JSON.parse(resultData)
    if (jsonData.action == 'started') {
      // 握手成功
      console.log('握手成功')
    } else if (jsonData.action == 'result') {
      const data = JSON.parse(jsonData.data)
      // 转写结果
      let resultTextTemp = ''
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.cn.st.rt.forEach((j: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        j.ws.forEach((k: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          k.cw.forEach((l: any) => {
            resultTextTemp += l.w
          })
        })
      })

      // 【最终】识别结果：
      this.translateState = data.cn.st.type == 0 ? 'end' : 'doing'
      console.log('识别结果:', resultTextTemp, this.translateState, this.isSpeaking)
      if (this._onTextResonse) {
        this._onTextResonse(resultTextTemp, this.translateState)
      }
    } else if (jsonData.action == 'error') {
      // 连接发生错误
      console.log('出错了:', jsonData)
      this.retryStrategy.excute()
    }
  }

  private connectWebSocket() {
    // 关闭上一次连接，去掉所有事件
    if (this.iatWS) {
      this.iatWS.onclose = null
      this.iatWS.onerror = null
      this.iatWS.onmessage = null
      this.iatWS.onopen = null
      this.iatWS = undefined
    }

    reTryConnectTimer = setTimeout(() => {
      this.retryStrategy.excute()
    }, 3000)

    const websocketUrl = this.getWebSocketUrl()
    if ('WebSocket' in window) {
      this.iatWS = new WebSocket(websocketUrl)
    } else if ('MozWebSocket' in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.iatWS = new MozWebSocket(websocketUrl)
    } else {
      alert('浏览器不支持WebSocket')
      return
    }
    if (this.iatWS) {
      this.iatWS.onopen = () => {
        reTryConnectTimer && clearTimeout(reTryConnectTimer)
        reTryConnectTimer = null
        this.retryStrategy.reset()
      }
      this.iatWS.onmessage = (e) => {
        this.renderResult(e.data)
      }
      this.iatWS.onerror = (e) => {
        console.error('iatWS.onerror', e)
      }
      this.iatWS.onclose = (e) => {
        console.warn('iatWS.onclose', e)
        if (e.code === 1006) {
          this.retryStrategy.excute()
        }
      }
    }
  }

  public stop() {
    this.iatWS?.close()
    this.iatWS = undefined
    this.recorder?.stop()
    this.recorder = null
    this.stream?.getTracks().forEach((track) => track.stop())
    this.stream = null
  }

  public onTextResonse(callback: (text: string, state: 'doing' | 'end', closed?: boolean) => void) {
    this._onTextResonse = callback
    if (!this.iatWS || this.iatWS.readyState === WebSocket.CLOSED) {
      this.connectWebSocket()
    }
  }

  private _speakStateCallBack: (isSpeaking: 'speaking' | 'ended') => void = (isSpeaking) => {
    console.log('isSpeaking', isSpeaking)
  }
  public subscribeVolume(callback: (isSpeaking: 'speaking' | 'ended') => void) {
    this._speakStateCallBack = callback
  }

  public onWujiStreamCompleted(callback: (stream: MediaStream) => void) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.onWujiStreamCompleted = (stream: MediaStream) => {
      // console.warn("onWujiStreamVolumeChange", instant);
      this.stream = stream
      callback(stream)
    }
  }

  private _paused = false
  public isPaused() {
    return this._paused
  }
  public pause() {
    this._paused = true
  }
  public continue() {
    this._paused = false
  }
}
