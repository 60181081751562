<script setup lang="ts">
import { userInfoUtil } from '@/assets/utils/UserInfo'
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { AudioPlayer } from '@/assets/utils/AudioPlayer'
import myAISocket from '@/assets/utils/MyAISocket'
import { askFirstQuestion, formatCurrentTimeToYYYYMMDDHHMMSS } from '@/assets/utils/common'
import AiPlayer from '@/components/AiPlayer.vue'
import type { asrState } from '@/assets/bean/common'
import type { VoceToTextBaseUtils } from '@/assets/utils/bean'
import { createTTSClass } from '@/assets/utils/VoiceToTextRealTime'
import { errorHandler } from '@/assets/utils/errorUtil'
import myLogger from '@/assets/utils/aliUtils/Logger'
import { recordLive } from '@/assets/service/httpService'

// 双向绑定变量
let showTalk = ref(false),
  avarta = ref(''),
  listenURL = ref(''),
  replyRealURL = ref(''),
  waitURL = ref(''),
  state = ref('notstart'),
  showEnd = ref(false),
  mediaURL = ref(''),
  mediaType = ref(''),
  showRealHuman = ref(false),
  realHuman = ref<HTMLVideoElement>(),
  voice = ref<HTMLAudioElement>()

// 双向绑定，计算变量
let backgroundImageStyle = computed(() => {
  return {
    backgroundImage: `url(${avarta.value})`
  }
})

// 路由处理变量
const router = useRouter()
const route = useRoute()

// 局部变量
let waitTimeTimer: NodeJS.Timeout | undefined,
  callVoiceTimer: NodeJS.Timeout | undefined,
  overTimeTimer: NodeJS.Timeout | undefined,
  waitOverTimeTimer: NodeJS.Timeout | undefined,
  sigleTalkLimitTimeTimer: NodeJS.Timeout | undefined,
  goPay = false,
  audioPlayer: AudioPlayer | undefined,
  voceToTextUtils: VoceToTextBaseUtils,
  toPlayMedia = { url: '', type: '', afterContent: '' },
  textArray: string[] = [],
  interuptedCallBack: (() => void) | undefined,
  forceStopAudioPlayer = false,
  prefix = 'response_',
  currentStreamID = ''

onBeforeMount(() => {
  // 获取身份相关数据
  listenURL.value = userInfoUtil.listenVideo
  replyRealURL.value = userInfoUtil.speakVideo
  waitURL.value = userInfoUtil.waitVideo
  avarta.value = userInfoUtil.avatar
})

onMounted(() => {
  callVoiceTimer = setTimeout(() => {
    voice.value!.muted = false
    callVoiceTimer = undefined
  }, 1000 * 1)

  // 单次聊天时长限制
  sigleTalkLimitTimeTimer = setTimeout(
    () => {
      myAISocket.TTV(
        '我妈妈叫我去写作业了，' + userInfoUtil.userName + ',我们下次再聊吧，一定要记得我哦',
        (data: any, complete?: boolean) => {
          setAudioTalk(data, !!complete)
          if (complete) {
            myLogger.log({
              page: 'chat',
              action: 'closeApp_overtime',
              mean: '超时AI主动再见'
            })
            setTimeout(() => {
              refuseTalk()
            }, 10 * 1000)
          }
        }
      )
    },
    1000 * 60 * 40 // 最大40min
  )

  // 响铃最多60S 如果被动呼叫接听等待最多10S
  const waitTime = route.query.waitTime as string
  waitTimeTimer = setTimeout(
    () => {
      myLogger.log({
        page: 'chat',
        action: 'auto_start_overtime',
        mean: '超时自动接听'
      })
      waitTimeTimer = undefined
      startTalk()
    },
    waitTime ? Number.parseInt(waitTime) : 60 * 1000
  )

  // 如果是微信打开，使用完成后，跳转到付费页面
  const toPay = route.query.toPay as string
  goPay = toPay == '1'

  //拦截安卓回退按钮
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  history.pushState(null, null, location.href)
  window.addEventListener('popstate', forbidBack)

  window.onStreamChanged = (type: 'ADD' | 'DELETE', streamID: string) => {
    myLogger.log({
      page: 'chat',
      action: 'subscribeStream',
      content: [type, streamID].join(','),
      mean: '流更新'
    })
    // 添加或删除流时触发
    // 流新增，且必须来自微信端，且此时没有正在拉的流，即 interuptedCallBack 是 undefined
    if (type === 'ADD' && !streamID.startsWith(prefix) && !interuptedCallBack) {
      pauseTTV()
      // 停止数字人说话，因为新的流需要说话
      // 停止播放媒体视频
      mediaURL.value = ''
      toPlayMedia = { url: '', type: '', afterContent: '' }
      // 停止播放数字人说话
      forceStopAudioPlayer = true
      currentStreamID = streamID
      // 停止录音，实时通话对语言延迟要求高
      // window.Android && window.Android.stopRecorder()
      myAISocket.TTV(
        userInfoUtil.userName + '，有电话找你，您先接听哈，我等会再过来找您',
        (data: any, complete?: boolean) => {
          // 恢复播放数字人说话
          forceStopAudioPlayer = false

          setAudioTalk(data, !!complete)
          state.value = 'listening'
          if (!interuptedCallBack && complete) {
            interuptedCallBack = () => {
              // 播放拉流
              // 显示推流
              window.Android && window.Android.startRealTimeTalk()
            }
          }
        }
      )
    } else if (
      // 流删除，且必须来自微信端，且此时的流是正在拉的流，即  currentStreamID == streamID
      !streamID.startsWith(prefix) &&
      interuptedCallBack &&
      currentStreamID == streamID
    ) {
      interuptedCallBack = undefined
      // 强制不说今天任务的第一句话
      userInfoUtil.sayHi = ''
      // 数字人恢复，接替真人
      showRealHuman.value = false
      showTalk.value = true
      // 停止通话
      window.Android && window.Android.stopRealTimeTalk()

      // 初始化语音转文字
      // initVoiceToText()

      // 主动开启第一句问候
      setTimeout(() => {
        startAsk()
        showEnd.value = false
      }, 1000)
    } else {
      myLogger.error({
        page: 'chat',
        action: 'subscribeStream',
        content: ['ignore', type, streamID, currentStreamID].join(','),
        mean: '异常流'
      })
    }
  }
})
onUnmounted(() => {
  myLogger.log('logout', '结束会话')
  unInit()
})

function setAudioTalk(data: any, complete?: boolean) {
  !forceStopAudioPlayer && audioPlayer && audioPlayer.setData(data, !!complete)
}
function startTalk() {
  // 关闭铃声，开始通话
  // eslint-disable-next-line no-undef
  voice.value!.muted = true
  callVoiceTimer && clearTimeout(callVoiceTimer)

  waitTimeTimer && clearTimeout(waitTimeTimer)

  // 初始化音频播放器
  initAudioPlayer()

  // 初始化语音转文字
  initVoiceToText()
  showTalk.value = true

  // 主动开启第一句问候
  setTimeout(() => {
    startAsk()
    showEnd.value = false
  }, 1000)

  // safari下，部分video不能自动播放
  document.querySelectorAll('video').forEach((v) => {
    if (v.paused) {
      v.play()
    }
  })

  // safari下，audio不能自动播放
  document.querySelectorAll('audio').forEach((a) => {
    if (a.paused) {
      a.play()
    }
  })
}

function refuseTalk() {
  myLogger.log('refuseTalk', goPay ? '跳转充值' : '关闭会话')
  if (goPay) {
    window.location.href = `https://weixin.dadanwuji.com/vip`
  } else {
    unInit()
  }
}

function initVoiceToText() {
  const streamID = prefix + userInfoUtil.getUserCount() + '_' + formatCurrentTimeToYYYYMMDDHHMMSS()
  // 启动语音转文字
  voceToTextUtils = createTTSClass()
  voceToTextUtils.updateKeyAndToken &&
    voceToTextUtils.updateKeyAndToken(
      userInfoUtil.vtt.appKey,
      userInfoUtil.vtt.token,
      streamID,
      userInfoUtil.maxSilence
    )

  // 开启监听实时语音转文字
  voceToTextUtils.onTextResonse((_text: string, _state: asrState, closed?: boolean) => {
    if (autoResponseTimer) {
      clearTimeout(autoResponseTimer)
      autoResponseTimer = undefined
    }

    if (closed) {
      errorHandler('抱歉！！程序发生错误,正在为您重启')
      return
    }

    !voceToTextUtils!.isPaused() && (state.value = 'speaking')
    // 数字人说话完成 且本次翻译结束，翻译内容不为空，才开始回复
    if (_state === 'end' && !voceToTextUtils!.isPaused() && _text.length > 0) {
      textArray.push(_text)
      askAI()
    } else if (_text.length > 100 && !voceToTextUtils!.isPaused()) {
      voceToTextUtils.pause()
      textArray.push(_text)
      askAI()
    }
  })

  voceToTextUtils.subscribeVolume((speakState: 'speaking' | 'ended') => {
    if (speakState === 'speaking' && state.value === 'waiting') {
      state.value = speakState
    }
  })
  voceToTextUtils.pause()
  recordLive(userInfoUtil.pid, streamID)
}
function initAudioPlayer() {
  // 初始化语音播放器
  if (!audioPlayer) {
    audioPlayer = new AudioPlayer(userInfoUtil.speakerID)
    audioPlayer.onPlayStart(() => {
      // todō 显示说话画面
      state.value = 'listening'
      // 只要是播放期间，都不能收音
      pauseTTV()
    })
    audioPlayer.onPlayEnd(() => {
      // 数字人说话完成，开始播放视频或显示照片，如果有的话
      mediaURL.value = toPlayMedia.url ?? ''
      mediaType.value = toPlayMedia.type ?? ''

      // 清除掉因为服务返回超时，已经丢弃的数据
      myAISocket.forceClearTimeOutResponse('ASK_AI')
      myAISocket.forceClearTimeOutResponse('TTV')
      // 上传丢失日志
      if (audioLog.log.length > 1) {
        myLogger.log(audioLog)
        audioLog = {
          page: 'chat',
          action: 'askAI_response_complete',
          mean: '数字人语音回复',
          event: 'talk',
          ask: '',
          response: '',
          media: '',
          log: []
        }
      }

      // 上传丢失日志
      if (audioLog0.log.length > 1) {
        myLogger.log(audioLog0)
        audioLog0 = {
          page: 'chat',
          action: 'startAsk',
          mean: '数字人说话，打招呼',
          ask: '',
          event: 'talk',
          log: ['']
        }
      }

      console.warn('onPlayEnd', toPlayMedia.url)
      if (interuptedCallBack) {
        myLogger.log({
          page: 'chat',
          action: 'onPlayEnd',
          mean: '语音播放完毕，开始播放实时RTC流'
        })
        interuptedCallBack()
        return
      }

      // 如果有媒体资源，需要显示，则不能立马监听用户说话
      if (toPlayMedia.url) {
        state.value = 'watchMedia'
        // 如果是图片，等待3.5s后才能开始说话
        if (mediaType.value === 'img') {
          myLogger.log({
            page: 'chat',
            action: 'onPlayEnd',
            mean: '语音播放完毕，开始播放图片'
          })
          setTimeout(() => {
            mediaEnded()
          }, 8 * 1000)
        } else {
          // 监听mv播放完后才能开始交流
          // aiplayer组建回调触发continueVTT，恢复对话
          myLogger.log({
            page: 'chat',
            action: 'onPlayEnd',
            mean: '语音播放完毕，开始播放视频'
          })
        }
      } else {
        myLogger.log({
          page: 'chat',
          action: 'onPlayEnd',
          mean: '语音播放完毕，继续监听用户说话'
        })
        // 继续监听用户说话
        continueVTT()
      }
      // todō 显示倾听页面
    })
  }
}
function uninitAudioPlayer() {
  if (audioPlayer) {
    audioPlayer.audioUninit()
    audioPlayer = undefined
  }
}

let audioLog0 = {
  page: 'chat',
  action: 'startAsk',
  mean: '数字人说话，打招呼',
  ask: '',
  event: 'talk',
  log: ['']
}
function startAsk() {
  // 第一句话，要等数字人先打招呼
  pauseTTV()

  const sayHi = userInfoUtil.sayHi
    ? userInfoUtil.sayHi
    : askFirstQuestion(userInfoUtil.replyConstraintsName!, userInfoUtil.userName)

  // 开启定时器，如果超过5000ms没有返回，则要提示“稍等一下”
  overTimeTimer = setTimeout(() => {
    overTimeTimer && (state.value = 'overTime')
    myLogger.error({
      page: 'chat',
      action: 'startAsk_overTime',
      content: sayHi,
      event: 'talk',
      mean: '数字人说话超时'
    })

    waitOverTimeTimer = setTimeout(() => {
      myLogger.error({
        page: 'chat',
        action: 'startAsk_overTime_restart',
        content: sayHi,
        event: 'talk',
        mean: '数字人说话超时，重启'
      })

      if (overTimeCount < 1) {
        audioPlayer && audioPlayer._onPlayEnd?.()
        overTimeCount++
      } else {
        errorHandler('抱歉程序发生错误')
      }
    }, 1000 * 5)
  }, 5000)

  myLogger.log({
    page: 'chat',
    action: 'startAsk',
    content: sayHi,
    event: 'talk',
    mean: '数字人说话'
  })

  // 计算请求花费时间
  let countTimeflag = false
  let start = new Date().getTime()
  audioLog0 = {
    page: 'chat',
    action: 'startAsk',
    mean: '数字人说话，打招呼',
    ask: sayHi,
    event: 'talk',
    log: []
  }
  let responseTotalLength = 0

  myAISocket.TTV(sayHi, (data: any, complete?: boolean) => {
    overTimeCount = 0
    setAudioTalk(data, !!complete)

    // 清除超时定时器
    cancelOverTimer()
    // 回复为监听状态
    state.value = 'listening'

    // 记录音频数据返回情况
    const timeUse = new Date().getTime() - start
    let buffer = data && data.data
    let dataLength = buffer && buffer.length
    responseTotalLength = responseTotalLength + (dataLength ?? 0)
    if (!countTimeflag) {
      countTimeflag = true
      audioLog0.log.push(`首帧数据长度:${dataLength ?? 0},耗时：${timeUse}ms`)
    } else {
      audioLog0.log.push(`数据长度:${dataLength ?? 0},耗时：${timeUse}ms`)
    }
    start = new Date().getTime()

    // 上传记录音频数据返回情况
    if (complete) {
      myLogger.log(audioLog0)
      audioLog0 = {
        page: 'chat',
        action: 'startAsk',
        mean: '数字人说话，打招呼',
        ask: '',
        event: 'talk',
        log: []
      }
      // 如果返回值为空，前置结束
      if (responseTotalLength == 0) {
        myLogger.log({
          page: 'chat',
          action: 'startAsk_none_response',
          content: sayHi,
          event: 'talk',
          mean: '服务异常，返回声音为空，强制跳过'
        })
        audioPlayer && audioPlayer._onPlayEnd?.()
      }
    }
  })
}
let retryPlayVideoCount = 0,
  originAfterContent = ''
function mediaEnded(err?: string) {
  if (toPlayMedia.afterContent) {
    if (err) {
      if (retryPlayVideoCount === 0) {
        originAfterContent = toPlayMedia.afterContent
        toPlayMedia.afterContent = '哎呀，这个视频有点问题，我给您换一个哈'
        toPlayMedia.url = toPlayMedia.url.replace(
          'https://static.dadanwuji.com',
          'https://wuji-web-app.oss-cn-shenzhen.aliyuncs.com'
        )
        //  播放器先预加载
        if (window.Android && toPlayMedia.url) {
          window.Android.prepareVideo(toPlayMedia.url)
        }
      } else {
        toPlayMedia.afterContent = '哎呀，这个视频还是有问题，我们先聊点别的好不好'
      }
    } else if (retryPlayVideoCount > 0) {
      toPlayMedia.afterContent = originAfterContent
    }

    myLogger.log({
      page: 'chat',
      action: 'mediaEnded',
      content: toPlayMedia.afterContent,
      mean: '视频播放完毕，数字人说话'
    })

    // 计算请求花费时间
    let countTimeflag = false
    let start = new Date().getTime()
    audioLog0 = {
      page: 'chat',
      action: 'mediaEnded',
      mean: '视频播放完毕，数字人说话',
      ask: toPlayMedia.afterContent,
      event: 'talk',
      log: []
    }
    let responseTotalLength = 0

    // 如果媒体播放完毕，需要再补充多问一句
    myAISocket.TTV(toPlayMedia.afterContent, (data: any, complete?: boolean) => {
      // 开始说话
      setAudioTalk(data, !!complete)
      state.value = 'listening'

      // 记录音频数据返回情况
      const timeUse = new Date().getTime() - start
      let buffer = data && data.data
      let dataLength = buffer && buffer.length
      responseTotalLength = responseTotalLength + (dataLength ?? 0)
      if (!countTimeflag) {
        countTimeflag = true
        audioLog.log.push(`首帧数据长度:${dataLength ?? 0},耗时：${timeUse}ms`)
      } else {
        audioLog.log.push(`数据长度:${dataLength ?? 0},耗时：${timeUse}ms`)
      }
      start = new Date().getTime()

      if (complete) {
        // 上传记录音频数据返回情况
        myLogger.log(audioLog)
        audioLog0 = {
          page: 'chat',
          action: 'mediaEnded',
          mean: '视频播放完毕，数字人说话',
          ask: toPlayMedia.afterContent,
          event: 'talk',
          log: []
        }
        // 如果返回值为空，前置结束
        if (responseTotalLength == 0) {
          myLogger.log({
            page: 'chat',
            action: 'mediaEnded_none_response',
            ask: toPlayMedia.afterContent,
            mean: '服务异常，返回声音为空，强制跳过'
          })
          audioPlayer && audioPlayer._onPlayEnd?.()
        }
        if (err && retryPlayVideoCount === 0) {
          retryPlayVideoCount++
          // do nothing
        } else {
          retryPlayVideoCount = 0
          // 清空媒体资源，数字人开始说话
          toPlayMedia = { url: '', type: '', afterContent: '' }
          mediaURL.value = toPlayMedia.url ?? ''
          mediaType.value = toPlayMedia.type ?? ''
        }
      }
    })
  } else {
    // 不需要再补充多问一句
    continueVTT()
  }
}

let audioLog = {
  page: 'chat',
  action: 'askAI_response_complete',
  mean: '数字人语音回复',
  event: 'talk',
  ask: '',
  response: '',
  media: '',
  log: ['']
}

let overTimeCount = 0
function askAI() {
  state.value = 'translating'
  // 暂停收音，因为数字人要开始说话
  pauseTTV()

  let text = textArray.join('/n')
  myLogger.log({
    page: 'chat',
    action: 'askAI',
    content: text,
    event: 'talk',
    mean: '用户说话'
  })

  textArray = []

  // 开启定时器，如果超过5000ms没有返回，则要提示“稍等一下”
  overTimeTimer = setTimeout(() => {
    overTimeTimer && (state.value = 'overTime')
    myLogger.error({
      page: 'chat',
      action: 'askAI_overTime',
      content: text,
      event: 'talk',
      mean: '用户说话超时'
    })

    waitOverTimeTimer = setTimeout(() => {
      myLogger.error({
        page: 'chat',
        action: 'askAI_overTime_restart',
        content: text,
        event: 'talk',
        mean: '用户说话超时，重启'
      })

      if (overTimeCount < 1) {
        audioPlayer && audioPlayer._onPlayEnd?.()
        overTimeCount++
      } else {
        errorHandler('抱歉程序发生错误')
      }
    }, 1000 * 5)
  }, 5000)

  // 计算请求花费时间
  let countTimeflag = false
  let start = new Date().getTime()
  audioLog = {
    page: 'chat',
    action: 'askAI_response_complete',
    mean: '数字人交互',
    ask: text,
    response: '',
    event: 'talk',
    media: '',
    log: []
  }
  let responseTotalLength = 0

  myAISocket.askAI(
    text,
    (
      data: any,
      media: {
        url: string
        type: 'mv' | 'img'
        afterContent: string
      },
      complete?: boolean,
      aiResponse?: string
    ) => {
      overTimeCount = 0
      setAudioTalk(data, !!complete)

      // 清除超时定时器
      cancelOverTimer()
      // 回复为监听状态
      state.value = 'listening'
      toPlayMedia = media ?? {}

      const timeUse = new Date().getTime() - start
      let buffer = data && data.data
      let dataLength = buffer && buffer.length
      responseTotalLength = responseTotalLength + (dataLength ?? 0)
      if (!countTimeflag) {
        countTimeflag = true
        audioLog.log.push(`首帧数据长度:${dataLength ?? 0},耗时：${timeUse}ms`)
      } else {
        audioLog.log.push(`数据长度:${dataLength ?? 0},耗时：${timeUse}ms`)
      }
      start = new Date().getTime()

      if (complete) {
        audioLog.media = JSON.stringify(media)
        audioLog.response = aiResponse ?? ''
        myLogger.log(audioLog)
        audioLog = {
          page: 'chat',
          action: 'askAI_response_complete',
          mean: '数字人语音回复',
          event: 'talk',
          ask: '',
          response: '',
          media: '',
          log: []
        }

        if (responseTotalLength == 0) {
          myLogger.log({
            page: 'chat',
            action: 'askAI_none_response',
            content: text,
            event: 'talk',
            mean: '服务异常，返回声音为空，强制跳过'
          })
          audioPlayer && audioPlayer._onPlayEnd?.()
        }
        //  播放器先预加载
        if (window.Android && toPlayMedia.url) {
          window.Android.prepareVideo(toPlayMedia.url)
        }
      }
    }
  )
}
function pauseTTV() {
  voceToTextUtils && voceToTextUtils.pause()
}

let autoResponseTimer: NodeJS.Timeout | undefined
function continueVTT() {
  state.value = 'waiting'
  setTimeout(() => {
    // 回调语音和视频的状态
    voceToTextUtils && voceToTextUtils?.continue()
  }, 600)

  // 10s内不回话，自动回复，请求一个“嗯，让AI主动说话”
  autoResponseTimer = setTimeout(() => {
    if (autoResponseTimer) {
      textArray.push('我们聊点别的吧')
      askAI()
      autoResponseTimer = undefined
    }
  }, 1000 * 7)
}
function toShowEnd() {
  myLogger.log({
    page: 'chat',
    action: 'toShowEnd',
    mean: '点击准备退出'
  })
  showEnd.value = true
  setTimeout(() => {
    showEnd.value = false
  }, 3000)
}
function cancelOverTimer() {
  // 清除超时定时器
  if (overTimeTimer) {
    console.log('startAsk clearTimeout')
    clearTimeout(overTimeTimer)
    overTimeTimer = undefined
  }
  if (waitOverTimeTimer) {
    clearTimeout(waitOverTimeTimer)
    waitOverTimeTimer = undefined
  }
}
function forbidBack(event: any) {
  console.warn('popstate', event)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  history.pushState(null, null, location.href)
}
function unInit() {
  cancelOverTimer()
  uninitAudioPlayer() // 释放音频播放器
  voceToTextUtils?.stop() // 停止语音转文字
  myAISocket.reset() // 重置AI聊天
  window.removeEventListener('popstate', forbidBack) //移除拦截安卓回退按钮
  toPlayMedia = { url: '', type: '', afterContent: '' }
  textArray = []

  // 清除定时器
  sigleTalkLimitTimeTimer && clearTimeout(sigleTalkLimitTimeTimer)
  waitTimeTimer && clearTimeout(waitTimeTimer)
  sigleTalkLimitTimeTimer = undefined
  // 清除用户信息
  userInfoUtil.reset()
  setTimeout(() => {
    router.push({
      path: 'login'
    })
  }, 500)
}
</script>
<template>
  <div class="home" v-show="!showTalk">
    <div class="logo"></div>
    <audio
      ref="voice"
      autoplay
      src="https://static.dadanwuji.com/app/android/incomming_phone.mp3"
      width="1px"
      height="1px"
      loop
      muted
    ></audio>
    <div class="avatarBG">
      <div class="avatar" :style="backgroundImageStyle"></div>
    </div>
    <div class="handlerBG">
      <div class="refuseBG">
        <span class="refuseBGIcon01"></span>
        <span class="refuseBGIcon02"></span>
        <button class="refuse" @click="refuseTalk">
          <span class="refuse_icon"></span>
          <span class="refuse_text">点击拒绝</span>
        </button>
      </div>
      <div class="acceptBG">
        <span class="acceptBGIcon01"></span>
        <span class="acceptBGIcon02"></span>
        <button class="accept" @click="startTalk">
          <span class="accept_icon"></span>
          <span class="accept_text">点击接听</span>
        </button>
      </div>
    </div>
  </div>
  <div class="chat" @click="toShowEnd" v-show="showTalk">
    <div class="logo"></div>
    <AiPlayer
      v-show="!showRealHuman"
      ref="aiPlayer"
      :state="state"
      :listenURL="listenURL"
      :replyRealURL="replyRealURL"
      :waitURL="waitURL"
      :mediaURL="mediaURL"
      :mediaType="mediaType"
      @onMediaEnded="mediaEnded"
    ></AiPlayer>
    <video
      v-show="showRealHuman"
      ref="realHuman"
      style="height: 100%; width: 100%"
      autoplay
    ></video>
    <div class="speak">
      <!--  -->
      <div class="tospeak" v-show="state === 'waiting'">
        <div class="micIcon"></div>
        <p>请说话</p>
      </div>

      <!--  -->
      <div class="speaking" v-show="state === 'speaking'">
        <img src="https://static.dadanwuji.com/app/android/talking_2x.gif" />
      </div>

      <!-- -->
      <div class="speakEnd" v-show="state === 'translating'">
        <div class="waitIcon">
          <icon></icon>
          <icon></icon>
          <icon></icon>
          <icon></icon>
          <icon></icon>
        </div>
        <p>语音传送中</p>
      </div>
    </div>
    <transition name="fade">
      <div class="refuseBG" v-show="showEnd">
        <button class="refuse" @click="refuseTalk">
          <span class="refuse_icon"></span>
          <span class="refuse_text">点击挂断</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<style lang="less" scoped>
@import '../assets/css/index.less';

@keyframes scaleFadeAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.home {
  padding: 35px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url('../assets/img/homeBackground@2x.png');
  position: relative;
  .logo {
    .pxToVW(129.5,width);
    .pxToVW(43,height);
    position: absolute;
    background-image: url(../assets/img/homeLogo@2x.png);
    background-size: contain;
    .pxToVW(60,left);
    .pxToVW(47,top);
    background-repeat: no-repeat;
  }

  .avatarBG {
    display: flex;
    justify-content: center;
    align-items: center;
    .pxToVW(440,width);
    .pxToVW(440,height);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../assets/img/homeAvatarBG@2x.png);
    .avatar {
      .pxToVW(420,width);
      .pxToVW(420,height);
      border: 0;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .handlerBG {
    display: flex;
    justify-content: space-around;
    align-items: start;
    width: 50%;
    .acceptBG,
    .refuseBG {
      .pxToVW(214,width);
      .pxToVW(214,height);
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        position: absolute;
        .pxToVW(125,width);
        .pxToVW(125,height);
        .pxToVW(125, border-radius);
        background-color: #f38a4d;
        animation: scaleFadeAnimation 2s ease-in-out infinite;
      }
      .acceptBGIcon01,
      .refuseBGIcon01 {
        animation-delay: 0ms;
      }
      .acceptBGIcon02,
      .refuseBGIcon02 {
        animation-delay: 1.5s;
      }
      .accept,
      .refuse {
        position: absolute;
        .pxToVW(125,width);
        .pxToVW(125,height);
        .pxToVW(125, border-radius);
        border: 0;
        background-image: url(../assets/img/homeAcceptBTN@2x.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .accept_icon,
        .refuse_icon {
          .pxToVW(63,width);
          .pxToVW(63,height);
          background-image: url(../assets/img/homePhone@2x.png);
          background-size: contain;
        }
        .accept_text,
        .refuse_text {
          .pxToVW(19, font-size);
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 18px;
          text-shadow: 1px 1px 11px rgba(36, 21, 9, 0.41);
          -webkit-background-clip: text;
          .pxToVW(20, margin-bottom);
        }
      }
      .accept {
        background-image: unset;
        background-color: #0bd10b;
      }
    }
    .acceptBG {
      > span {
        background-color: #28ca28f0;
      }
    }
  }
}

/* 竖屏样式 */
@media screen and (orientation: portrait) {
  /* 在竖屏时应用的样式 */
  .home {
    .avatarBG {
      .pxToVW(546*2,width);
      .pxToVW(546*2,height);
      .avatar {
        .pxToVW(520*2,width);
        .pxToVW(520*2,height);
      }
    }
    .handlerBG {
      width: 90%;
      .acceptBG,
      .refuseBG {
        .pxToVW(214,width);
        .pxToVW(214,height);
        .pxToVW(61.5*2, margin-top);
        > span {
          position: absolute;
          .pxToVW(125*2,width);
          .pxToVW(125*2,height);
          .pxToVW(125*2, border-radius);
        }
        .accept,
        .refuse {
          .pxToVW(250,width);
          .pxToVW(250,height);
          .pxToVW(250, border-radius);
          .accept_icon,
          .refuse_icon {
            .pxToVW(63*1.5,width);
            .pxToVW(63*1.5,height);
          }
          .accept_text,
          .refuse_text {
            .pxToVW(19*2, font-size);
          }
        }
      }
    }
  }
}

@keyframes circleFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.chat {
  width: 100%;
  height: 100%;
  background: black;
  .logo {
    z-index: 1;
    .pxToVW(129.5,width);
    .pxToVW(43,height);
    position: absolute;
    background-image: url(../assets/img/chatLog@2x.png);
    background-size: contain;
    .pxToVW(60,left);
    .pxToVW(47,top);
    background-repeat: no-repeat;
  }

  .subtitles {
    bottom: 5px;
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    font-size: 20px;
    font-family: fangsong;
    font-weight: 800;
    color: white;
  }
  .speak {
    z-index: 2;
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;

    .tospeak,
    .speaking,
    .speakEnd {
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .tospeak {
      .pxToVW(196,height);
      background-image: url(../assets/img/chatToStartMicBG@2x.png);
      .micIcon {
        background-image: url(../assets/img/chatToStartMic@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        .pxToVW(66,height);
        .pxToVW(66,width);
      }
      p {
        .pxToVW(50,font-size);
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        font-weight: 600;
        color: #ffffff;
        .pxToVW(50,line-height);
        margin-bottom: 0;
      }
    }
    .speaking {
      .pxToVW(105,height);
      background-image: url(../assets/img/chatToStartBG@2x.png);
      video,
      img {
        width: 100%;
        height: 100%;
      }
    }
    .speakEnd {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .pxToVW(484,width);
      .pxToVW(127,height);
      background-image: url(../assets/img/chatTalkEndBG@2x.png);
      .waitIcon {
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        icon {
          animation: circleFade 2.5s infinite;
          .pxToVW(14,height);
          .pxToVW(14,width);
          .pxToVW(14,margin-right);
          background-size: contain;
          background-repeat: repeat-x;
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../assets/img/circle@2x.png);
        }
        icon:nth-child(1) {
          animation-delay: 0s;
        }
        icon:nth-child(2) {
          animation-delay: 0.5s;
        }
        icon:nth-child(3) {
          animation-delay: 1s;
        }
        icon:nth-child(4) {
          animation-delay: 1.5s;
        }
        icon:nth-child(5) {
          animation-delay: 2s;
        }
      }
      p {
        .pxToVW(50,font-size);
        .pxToVW(50,line-height);
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 10px 0 0 0;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .refuseBG {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
    position: absolute;
    bottom: 44vh;
    z-index: 2;
    .refuse {
      position: absolute;
      .pxToVW(125,width);
      .pxToVW(125,height);
      .pxToVW(125, border-radius);
      border: 0;
      background-image: url(../assets/img/homeAcceptBTN@2x.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .refuse_icon {
        .pxToVW(63,width);
        .pxToVW(63,height);
        background-image: url(../assets/img/homePhone@2x.png);
        background-size: contain;
      }
      .refuse_text {
        .pxToVW(19, font-size);
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
        text-shadow: 1px 1px 11px rgba(36, 21, 9, 0.41);
        -webkit-background-clip: text;
        .pxToVW(20, margin-bottom);
      }
    }
  }
}

/* 竖屏样式 */
@media screen and (orientation: portrait) {
  .chat {
    .speak {
      .tospeak,
      .speaking,
      .speakEnd {
        background-size: cover;
      }
      .tospeak {
        .pxToVW(196*2.2,height);
        .micIcon {
          .pxToVW(66*2,height);
          .pxToVW(66*2,width);
        }
        p {
          .pxToVW(50*1.5,font-size);
        }
      }
      .speaking {
        .pxToVW(105*2,height);
      }
      .speakEnd {
        .pxToVW(484*2,width);
        .pxToVW(127*2,height);
        p {
          .pxToVW(50*1.5,font-size);
          .pxToVW(50*1.5,line-height);
        }
      }
    }
    .refuseBG {
      .refuse {
        .pxToVW(250,width);
        .pxToVW(250,height);
        .pxToVW(250, border-radius);
        .refuse_icon {
          .pxToVW(63*1.5,width);
          .pxToVW(63*1.5,height);
        }
        .refuse_text {
          .pxToVW(19*2, font-size);
        }
      }
    }
  }
}
</style>
