import axios from 'axios'

export async function recordLive(pid: string, streamID: string): Promise<void> {
  const url: string = import.meta.env.VITE_APP_API_HTTP_URL + '/api/recordLive'

  const result = await axios.post(url, {
    pid,
    streamID
  })
  const data = result.data
  if (!data.success) {
    console.error('record Live error', JSON.stringify(data))
  } else {
    console.log('record Live success', JSON.stringify(data))
  }
}
