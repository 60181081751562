<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { isWeChat } from '@/assets/utils/common'
import Modal from 'ant-design-vue/es/modal/Modal'
import myLogger from '@/assets/utils/aliUtils/Logger'

const props = defineProps({
  mediaType: String,
  mediaURL: String,
  waitURL: String,
  listenURL: String,
  replyRealURL: String,
  state: String
})

const waitingVideo = ref<HTMLVideoElement>()
const listenVideo = ref<HTMLVideoElement>()
const replyRealVideo = ref<HTMLVideoElement>()
const mediaVideo = ref<HTMLVideoElement>()
const isWechat = ref(isWeChat())
const isApp = ref(!!window.Android)

function toPlayMedia() {
  const modal = Modal.info({
    title: '提示',
    content: '因微信平台限制，无法自动播放，请手动点击确定播放；购买专属硬件无此限制',
    okText: '播放',
    onOk() {
      mediaVideo.value!.muted = false
      mediaVideo.value!.play()
      modal.destroy()
    }
  })
}

function resetListenVideo() {
  if (listenVideo.value) {
    listenVideo.value.currentTime = 0
    try {
      listenVideo.value.play()
    } catch (error) {
      console.warn('listenVideo play error')
    }
  }
  muteWaitVideo()
}
function resetReplyVideo() {
  if (replyRealVideo.value) {
    replyRealVideo.value.currentTime = 0
    try {
      replyRealVideo.value.play()
    } catch (error) {
      console.warn('replyRealVideo play error')
    }
  }
  muteWaitVideo()
}
function playWaitVideo() {
  if (waitingVideo.value) {
    waitingVideo.value.currentTime = 0
    waitingVideo.value.muted = false
    try {
      waitingVideo.value.play()
    } catch (error) {
      console.warn('waitingVideo play error')
    }
  }
}
function muteWaitVideo() {
  if (waitingVideo.value && !waitingVideo.value.muted) {
    waitingVideo.value.muted = true
  }
}
function onMediaCanPlay() {
  if (mediaVideo.value) {
    if (isWechat.value) {
      toPlayMedia()
    } else {
      try {
        mediaVideo.value.muted = false
        mediaVideo.value.play()
      } catch (error) {
        toPlayMedia()
        console.warn('theVideo play error')
      }
    }
  }
}

const emit = defineEmits(['onMediaEnded'])
function onMediaEnded() {
  emit('onMediaEnded')
}
function onWaitEnded() {
  waitingVideo.value && (waitingVideo.value.muted = true)
}

onMounted(() => {
  document.querySelectorAll('video').forEach((video) => {
    video.addEventListener('contextmenu', function (e) {
      e.preventDefault()
    })
  })
})

watch(
  () => props.state,
  (newValue: string | undefined, oldVaue: string | undefined) => {
    // 没有变化，不做任何操作
    if (newValue == oldVaue) return

    // 先显示视频，再播放视频
    setTimeout(() => {
      myLogger.log({
        page: 'chat',
        action: 'aiPlayer',
        mean:
          newValue === 'listening'
            ? '数字人动嘴'
            : newValue === 'overTime'
              ? '数字人超时'
              : '数字人倾听'
      })
      // 根据显示视频类型，调整视频状态
      muteWaitVideo()
      if (newValue === 'listening') {
        resetReplyVideo()
      } else if (newValue === 'overTime') {
        playWaitVideo()
      } else if (newValue === 'speaking') {
        resetListenVideo()
      }
    }, 40)
  }
)

watch(
  () => props.mediaURL,
  (newValue: string | undefined, oldVaue: string | undefined) => {
    // 没有变化，不做任何操作
    if (newValue == oldVaue) return

    // 只有app打开，才处理该逻辑
    if (isApp.value && newValue?.endsWith('mp4')) {
      // 先显示video,再控制
      setTimeout(() => {
        // 设置监听播放完成事件
        window.onVideoPlayEnd = (err?: string) => {
          if (err) {
            myLogger.error({
              page: 'chat',
              action: 'aiPlayer_playVideo',
              media: newValue,
              mean: '播放视频结束'
            })
          } else {
            myLogger.log({
              page: 'chat',
              action: 'aiPlayer_playVideo',
              media: newValue,
              mean: '播放视频失败'
            })
          }
          emit('onMediaEnded', err)
        }
        myLogger.log({
          page: 'chat',
          action: 'aiPlayer_playVideo',
          media: newValue,
          mean: '播放视频'
        })
        window.Android.playVideo(newValue!)
      }, 40)
    }
  }
)
</script>

<template>
  <div class="playerContainer">
    <div
      :class="{
        AIBot: true,
        hasMedia: mediaURL
      }"
    >
      <video
        draggable="false"
        loop
        muted
        @ended="onWaitEnded"
        x5-playsinline
        playsinline
        webkit-playsinline="true"
        ref="waitingVideo"
        :src="waitURL"
        :style="{
          opacity: state === 'overTime' ? 1 : 0
        }"
      ></video>
      <video
        draggable="false"
        loop
        autoplay
        muted
        x5-playsinline
        playsinline
        webkit-playsinline="true"
        ref="listenVideo"
        :src="listenURL"
        :style="{
          opacity: ['waiting', 'speaking', 'translating', 'watchMedia'].includes(props.state!)
            ? 1
            : 0
        }"
      ></video>
      <video
        draggable="false"
        loop
        autoplay
        muted
        x5-playsinline
        playsinline
        webkit-playsinline="true"
        ref="replyRealVideo"
        :src="replyRealURL"
        :style="{ opacity: state === 'listening' ? 1 : 0 }"
      ></video>
    </div>
    <div v-show="!!mediaURL" class="MediaContainer">
      <video
        v-if="mediaURL && mediaType === 'mv' && !isApp"
        draggable="false"
        @ended="onMediaEnded"
        autoplay
        muted
        x5-playsinline
        playsinline
        preload="auto"
        @loadeddata="onMediaCanPlay"
        webkit-playsinline="true"
        ref="mediaVideo"
        :src="mediaURL"
      ></video>
      <img v-if="mediaURL && mediaType === 'img'" :src="mediaURL" ref="mediaImg" />
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../assets/css/index.less';
.playerContainer {
  width: 100%;
  height: 100%;
  background: url('../assets/img/homeBackground@2x.png');
  .AIBot {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      height: 100%;
      width: 100%;
      position: absolute;
      object-fit: cover;
      z-index: 1;
    }
  }
  .hasMedia {
    video {
      right: 0;
      top: 0;
      width: auto;
      height: 12vw;
      object-fit: cover;
    }
  }

  .MediaContainer {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video,
    img {
      // position: absolute;
      // height: 80vh;
      // width: auto;
      // left: 20px;
      // top: 20px;
      position: absolute;
      height: 100vh;
      width: auto;
    }
  }
}
</style>
