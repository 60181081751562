import type { asrState } from '@/assets/bean/common'
import type { VoceToTextBaseUtils } from '../bean'
export class VoceToTextAndroidUtils implements VoceToTextBaseUtils {
  private APPKEY!: string
  private TOKEN!: string
  private STREAMID!: string
  private maxSilence!: number

  public updateKeyAndToken(appkey: string, token: string, streamid: string, maxSilence: number) {
    this.APPKEY = appkey
    this.TOKEN = token
    this.STREAMID = streamid
    this.maxSilence = maxSilence
  }

  public onTextResonse(callback: (text: string, state: asrState, closed?: boolean) => void) {
    if (this.APPKEY && this.TOKEN) {
      window.onWujiAliTextResponse = (text: string) => {
        const param = JSON.parse(text)
        callback(param.text, param.state, param.isEnd == 1)
      }
      window.Android.initASR(this.APPKEY, this.TOKEN, this.STREAMID, this.maxSilence)
    } else {
      throw new Error('请先调用updateKeyAndToken方法传入appkey和token')
    }
  }

  public subscribeVolume(callback: (isSpeaking: 'speaking' | 'ended') => void) {
    window.onWujiVolumeChange = callback
  }

  private _paused = false
  public isPaused() {
    return this._paused
  }
  public pause() {
    if (this._paused) return
    this._paused = true
    window.Android.toggleRecorder(true)
  }
  public continue() {
    if (!this._paused) return
    this._paused = false
    window.Android.toggleRecorder(false)
  }
  public stop() {
    window.Android.stopRecorder()
  }
}
