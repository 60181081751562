import { VoceToTextAndroidUtils } from './aliUtils/VoiceToTextRealTimeAndroid'
import { VoceToTextWebUtils } from './aliUtils/VoiceToTextRealTimeWeb'
import { VoceToTextWebUtils as VoceToTextXunfeiWebUtils } from './xunfei/VoiceToTextRealTime'
import type { VoceToTextBaseUtils } from './bean'

// 工厂函数，根据条件返回不同类型的对象，但都符合 VoceToTextBaseUtils 接口
export function createTTSClass(name: 'ali' | 'xunfei' = 'ali'): VoceToTextBaseUtils {
  if (window.Android) {
    if (name === 'ali') {
      return new VoceToTextAndroidUtils()
    } else {
      console.error('暂时未实现')
      return new VoceToTextAndroidUtils()
    }
  } else {
    if (name === 'ali') {
      return new VoceToTextWebUtils()
    } else {
      return new VoceToTextXunfeiWebUtils()
    }
  }
}
