import myAISocket from './MyAISocket'
import { userInfoUtil } from './UserInfo'

export function toBase64(buffer: ArrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

// 进入全屏模式
export function enterFullscreen() {
  // safari就放弃全屏
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  if (isSafari) {
    return
  }
  try {
    const element = document.documentElement // 获取整个文档的根元素

    if (element.requestFullscreen) {
      element.requestFullscreen() // 标准方式
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (element.mozRequestFullScreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element.mozRequestFullScreen() // Firefox
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (element.webkitRequestFullscreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element.webkitRequestFullscreen() // Chrome, Safari 和 Opera
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (element.msRequestFullscreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element.msRequestFullscreen() // Internet Explorer
    }
  } catch (error) {
    console.error('enterFullscreen', error)
  }
}

// 退出全屏模式
export function exitFullscreen() {
  try {
    const fullscreenElement =
      document.fullscreenElement ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.webkitFullscreenElement ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.mozFullScreenElement
    if (!fullscreenElement) {
      return
    }
    if (document.exitFullscreen) {
      document.exitFullscreen() // 标准方式
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (document.mozCancelFullScreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.mozCancelFullScreen() // Firefox
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (document.webkitExitFullscreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.webkitExitFullscreen() // Chrome, Safari 和 Opera
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (document.msExitFullscreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.msExitFullscreen() // Internet Explorer
    }
  } catch (error) {
    console.warn('exitFullscreen failed!')
  }
}

export function generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return randomString
}

export function generateRandomNumber(length: number): string {
  const characters = '123456789'
  let randomString = ''
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return randomString
}
export function generateRandom32String(length: number = 32): string {
  const characters = '123456789'
  let randomString = ''
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return randomString
}

export function getTargetDeviceID(): Promise<(string | undefined)[]> {
  return new Promise((resolve, reject) => {
    // 检查浏览器是否支持enumerateDevices方法
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      // 枚举所有的媒体设备
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          // 过滤出音频输入设备（麦克风）和音频输出设备（扬声器）
          const audioInputDevices = devices.filter((device) => device.kind === 'audioinput')
          const audioOutputDevices = devices.filter((device) => device.kind === 'audiooutput')

          // 打印麦克风设备列表
          audioInputDevices.forEach((device) => {
            console.log(`麦克风设备ID: ${device.deviceId}, 设备名称: ${device.label}`)
            userInfoUtil.micID = device.deviceId
          })

          // 打印扬声器设备列表
          audioOutputDevices.forEach((device) => {
            console.log(`扬声器设备ID: ${device.deviceId}, 设备名称: ${device.label}`)
          })
          let targetInputID, targetOutputID
          if (audioInputDevices && audioInputDevices.length > 0) {
            targetInputID = audioInputDevices.shift()?.deviceId
          }
          if (audioOutputDevices && audioOutputDevices.length > 0) {
            targetOutputID = audioOutputDevices.shift()?.deviceId
          }
          resolve([targetInputID, targetOutputID])
        })
        .catch((error) => {
          console.error('无法枚举设备:', error)
          throw error
        })
    } else {
      throw new Error('当前浏览器不支持enumerateDevices方法')
    }
  })
}

export async function meidaURL2Local(url: string): Promise<string> {
  try {
    const response = await fetch(url)
    if (response.ok) {
      const blob = await response.blob()
      return URL.createObjectURL(blob)
    } else {
      throw new Error('mediaURL2Local error')
    }
  } catch (error) {
    console.error('meidaURL2Local:', url, error)
    throw error
  }
}

export function meidaURL2LocalCall(url: string, callBack: (url: string) => void): void {
  fetch(url)
    .then((response) => {
      if (response.ok) {
        return response.blob()
      } else {
        throw new Error('meidaURL2LocalCall error')
      }
    })
    .then((blob) => {
      callBack(URL.createObjectURL(blob))
    })
    .catch((error) => {
      console.error('meidaURL2LocalCall:', url, error)
      throw error
    })
}

export function getURLParam(paramName: string) {
  const params = new URLSearchParams(window.location.search)

  // 获取参数值
  const paramValue = params.get(paramName)

  return paramValue
}

export function fomatterDate(param: string) {
  // 获取当前时间
  const currentDate = new Date(param)

  // 获取月份和日期
  const month = currentDate.getMonth() + 1 // 月份从 0 开始，所以需要加 1
  const day = currentDate.getDate()

  // 格式化为 MM-DD 形式
  return (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day
}

export function completeData<T extends { date: string }, K extends keyof T>(
  data: T[],
  valueName: K,
  valueName_2?: K
): T[] {
  data = data.map((item) => {
    item.date = fomatterDate(item.date)
    return item
  })
  const currentDate = new Date()
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(currentDate.getDate() - 30)

  const datesInRange: string[] = []
  const completedData: T[] = []

  // 生成近30天的日期列表
  const dateIterator = new Date(thirtyDaysAgo)
  while (dateIterator <= currentDate) {
    datesInRange.push(dateIterator.toISOString().split('T')[0].substring(5))
    dateIterator.setDate(dateIterator.getDate() + 1)
  }

  // 补全数据
  for (const date of datesInRange) {
    const existingData = data.find((item) => item.date === date)
    if (existingData) {
      completedData.push(existingData)
    } else {
      let newData
      if (valueName_2) {
        newData = {
          date,
          [valueName]: 0,
          [valueName_2]: 0
        } as T
      } else {
        newData = {
          date,
          [valueName]: 0
        } as T
      }

      completedData.push(newData)
    }
  }

  return completedData
}

export function getRandomItems<T>(array: T[], n: number): T[] {
  if (n >= array.length) {
    return array // return the whole array if n is greater than or equal to the array length
  }

  const shuffledArray = array.slice() // create a copy of the original array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]] // shuffle the array
  }

  return shuffledArray.slice(0, n) // return the first n items of the shuffled array
}

export function getPid() {
  return (
    userInfoUtil.getUserCount() +
    '@' +
    new Date().getFullYear() +
    '/' +
    new Date().getMonth() +
    '/' +
    new Date().getDate() +
    '-' +
    new Date().getHours() +
    '-' +
    new Date().getMinutes() +
    '-' +
    new Date().getSeconds()
  )
}

export function askFirstQuestion(botName: string, userName: string) {
  // 如果页面没有关闭，可以继续聊
  if (myAISocket.seq !== 0) {
    return botName + '这边信号不好，断开了，我们继续聊吧！'
  }

  // 如果页面关闭了，重新问好
  let hello = '早上好呀'
  const hour = new Date().getHours()
  if (hour < 8 && hour >= 4) {
    hello = '早上好呀'
  } else if (hour < 12 && hour >= 8) {
    hello = '上午好呀'
  } else if (hour < 14 && hour >= 12) {
    hello = '中午好呀'
  } else if (hour < 18 && hour >= 14) {
    hello = '下午好呀'
  } else {
    hello = '晚上好呀'
  }
  let senceList: string[] = []
  if (['小西瓜', '小叶子'].includes(botName)) {
    senceList = [
      '您是' + userName + '吗，我是' + botName + '，我一个人在家写作业有点寂寞，您能陪陪我聊聊天吗',
      userName + '，' + hello + '我刚做噩梦睡不着，有点害怕，您能陪陪我，聊聊天吗'
    ]
  } else {
    senceList = [
      userName + '，我是' + botName + '，我想你了，正好现在有时间，咋俩聊会天呗',
      userName + '，' + hello + '，能听到我说话么？',
      userName + '，我是' + botName + '，能看到我吗？'
    ]
  }

  return senceList[Math.floor(senceList.length * Math.random())]
}

// 检查设备是否为iPhone
function isiPhone() {
  return /iPhone/i.test(navigator.userAgent)
}

// 检查浏览器是否为Safari
function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

// 检查浏览器是否为微信浏览器
export function isWeChat() {
  return /micromessenger/i.test(navigator.userAgent)
}

// 判断设备是否为iPhone，并且是否使用Safari或微信浏览器
export function isiPhoneWithSafariOrWeChat() {
  return isiPhone() && (isSafari() || isWeChat())
}

export function getUrlParams(key: string): string {
  const searchParams = window.location.search.substr(1).split('&')
  const params: { [index: string]: string } = {}

  for (const param of searchParams) {
    const [key, value] = param.split('=')
    params[key] = decodeURIComponent(value)
  }
  return params[key] ?? ''
}

export function formatCurrentTimeToYYYYMMDDHHMMSS(): string {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth() + 1 // getMonth() 返回的月份是从 0 开始的
  const day = now.getDate()
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const seconds = now.getSeconds()

  // 使用 padStart 确保月、日、小时、分钟和秒都是两位数的字符串
  const formattedDate = [
    year.toString(),
    (month < 10 ? '0' : '') + month.toString(),
    (day < 10 ? '0' : '') + day.toString(),
    (hours < 10 ? '0' : '') + hours.toString(),
    (minutes < 10 ? '0' : '') + minutes.toString(),
    (seconds < 10 ? '0' : '') + seconds.toString()
  ].join('')

  return formattedDate
}

export function formatCurrentTimeToHHMMSS(): string {
  const now = new Date()
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const seconds = now.getSeconds()

  // 使用 padStart 确保月、日、小时、分钟和秒都是两位数的字符串
  const formattedDate = [
    (hours < 10 ? '0' : '') + hours.toString(),
    (minutes < 10 ? '0' : '') + minutes.toString(),
    (seconds < 10 ? '0' : '') + seconds.toString()
  ].join(':')

  return formattedDate
}

export function getPageLoadTime(): number {
  if (
    performance &&
    performance.timing &&
    performance.timing.loadEventEnd &&
    performance.timing.navigationStart
  ) {
    // 页面完全加载完成所需的时间（从开始导航到 loadEventEnd）
    return performance.timing.loadEventEnd - performance.timing.navigationStart
  }
  return 0
}
