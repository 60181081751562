import { errorHandler } from '../errorUtil'
import { RetryStrategy } from '../retryUtil'
import { generateRandom32String } from '../common'
import type { asrState } from '@/assets/bean/common'
import Recorder from 'js-audio-recorder'
import type { VoceToTextBaseUtils } from '../bean'
let interval: NodeJS.Timeout | null = null
let reTryConnectTimer: NodeJS.Timeout | null = null
let volumeTimer: NodeJS.Timeout | null = null
export class VoceToTextWebUtils implements VoceToTextBaseUtils {
  private iatWS?: WebSocket
  private recorder: Recorder | null = null
  private stream: MediaStream | null = null
  resultText: string[] = []
  private translateState: asrState = 'end'
  private _onTextResonse?: (text: string, state: asrState, closed?: boolean) => void
  private _isSpeaking: 'ended' | 'speaking' = 'ended'
  retryStrategy: RetryStrategy
  private messageID = generateRandom32String()
  private taskID = generateRandom32String()

  private get isSpeaking() {
    return this._isSpeaking
  }
  private set isSpeaking(state: 'ended' | 'speaking') {
    this._isSpeaking = state
    this._speakStateCallBack && this._speakStateCallBack(state)
  }

  constructor() {
    this.initRecorder()
    // 初始化重试策略，添加重试执行器
    this.retryStrategy = new RetryStrategy(() => {
      this.connectWebSocket()
    })
    // 所有重试执行完，最后失败后的回调
    this.retryStrategy.onFinish(() => {
      // 回调所有等待事件，但是没有任何返回参数
      errorHandler('语音识别失败')
    })
  }

  _token = ''
  _appKey = ''
  public updateKeyAndToken(appkey: string, token: string) {
    this._token = token
    this._appKey = appkey
  }

  private initRecorder() {
    this.recorder = new Recorder({
      sampleBits: 16, // 采样位数，，默认是16
      sampleRate: 16000, //音频采样率，默认是16000Hz，
      numChannels: 1, // 声道，支持 1 或 2， 默认是1
      compiling: true // 是否边录边转换，默认是false
    })
    this.recorder.onprogress = (payload: {
      duration: number
      fileSize: number
      vol: number
      data: Array<DataView>
    }) => {
      if (this._paused) {
        return
      }
      if (payload.vol > 0.01) {
        if (volumeTimer) {
          clearTimeout(volumeTimer)
          volumeTimer = null
        }
        this.isSpeaking = 'speaking'
      } else if (payload.vol <= 0.01) {
        if (!volumeTimer) {
          volumeTimer = setTimeout(() => {
            this.isSpeaking = 'ended'
          }, 0.2 * 1000)
        }
      }
    }
    this.recorder.start()
  }

  private getWebSocketUrl(): string {
    // 请求地址根据语种不同变化
    const url = 'wss://nls-gateway-cn-shanghai.aliyuncs.com/ws/v1'
    return `${url}?token=${this._token}`
  }

  private renderResult(resultData: string) {
    const jsonData = JSON.parse(resultData)
    const eventName = jsonData.header.name
    const payload = jsonData.payload // 获取响应文字
    switch (eventName) {
      case 'TranscriptionStarted': // 服务端已经准备好了进行识别
        //获取音频信息，定时获取并发送
        interval = setInterval(() => {
          this.getPCMAndSend()
        }, 500)
        break
      case 'SentenceBegin': // 服务端检测到了一句话的开始。
        // to implement
        this._onTextResonse && this._onTextResonse('', 'start')
        break
      case 'TranscriptionResultChanged': // 识别结果发生了变化。
        // to implement
        this._onTextResonse && this._onTextResonse(payload.result, 'doing')
        break
      case 'SentenceEnd': //  事件表示服务端检测到了一句话的结束。
        // to implement
        this._onTextResonse && this._onTextResonse(payload.result, 'end')
        break
      case 'TranscriptionCompleted': //  事件表示服务端已停止了语音转写。
        // to implement
        break
      default:
        console.warn('未知事件')
        break
    }
  }

  //获取音频信息，并发送
  private getPCMAndSend() {
    if (!this.recorder) {
      return
    }
    //获取音频信息
    const NextData: DataView[] = this.recorder.getNextData()

    if (!NextData.length) {
      return
    }
    const byteLength = NextData[0].byteLength
    const buffer = new ArrayBuffer(NextData.length * byteLength)
    const dataView = new DataView(buffer)

    // 数据合并
    for (let i = 0, iLen = NextData.length; i < iLen; ++i) {
      for (let j = 0, jLen = NextData[i].byteLength; j < jLen; ++j) {
        dataView.setInt8(i * byteLength + j, NextData[i].getInt8(j))
      }
    }

    const blob = new Blob([dataView])
    const blob_size = blob.size

    //ali最大支持3200字节的音频
    const max_blob_size = 3200 //支持1600 或3200
    const my_num = 1 + blob_size / max_blob_size

    //切分音频发送
    for (let i = 0; i < my_num; i++) {
      let end_index_blob = max_blob_size * (i + 1)
      //判断结束时候的分界
      if (end_index_blob > blob_size) {
        end_index_blob = blob_size
      }
      //切分音频
      const blob2 = blob.slice(i * max_blob_size, end_index_blob)
      //生成新的blob
      const newbolb = new Blob([blob2], { type: 'audio/pcm' })

      if (this._paused) {
        this.iatWS?.send(new Blob(new Array(blob2.size).fill(0), { type: 'audio/pcm' }))
      } else {
        this.iatWS?.send(newbolb)
      }
    }
  }

  private startTranscription() {
    this.iatWS?.send(
      JSON.stringify({
        header: {
          message_id: this.messageID,
          task_id: this.taskID,
          namespace: 'SpeechTranscriber',
          name: 'StartTranscription',
          appkey: this._appKey
        },
        payload: {
          format: 'PCM',
          sample_rate: 16000,
          enable_intermediate_result: true,
          enable_punctuation_prediction: true,
          enable_inverse_text_normalization: true,
          max_sentence_silence: 1000
        }
      })
    )
  }

  private stopTranscription() {
    this.iatWS?.send(
      JSON.stringify({
        header: {
          message_id: this.messageID,
          task_id: this.taskID,
          namespace: 'SpeechTranscriber',
          name: 'StopTranscription',
          appkey: this._appKey
        }
      })
    )
  }

  private connectWebSocket() {
    // 关闭上一次连接，去掉所有事件
    if (this.iatWS) {
      this.iatWS.onclose = null
      this.iatWS.onerror = null
      this.iatWS.onmessage = null
      this.iatWS.onopen = null
      this.iatWS = undefined
    }

    reTryConnectTimer = setTimeout(() => {
      this.retryStrategy.excute()
    }, 3000)

    const websocketUrl = this.getWebSocketUrl()
    if ('WebSocket' in window) {
      this.iatWS = new WebSocket(websocketUrl)
    } else if ('MozWebSocket' in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.iatWS = new MozWebSocket(websocketUrl)
    } else {
      alert('浏览器不支持WebSocket')
      return
    }
    if (this.iatWS) {
      this.iatWS.onopen = (e) => {
        reTryConnectTimer && clearTimeout(reTryConnectTimer)
        reTryConnectTimer = null
        this.retryStrategy.reset()

        // 请求开始录音
        this.startTranscription()
      }
      this.iatWS.onmessage = (e) => {
        this.renderResult(e.data)
      }
      this.iatWS.onerror = (e) => {
        console.error('iatWS.onerror', e)
        this.recorder?.stop()
      }
      this.iatWS.onclose = (e) => {
        console.warn('iatWS.onclose', e)
        if (e.code === 1006) {
          this.retryStrategy.excute()
        }
      }
    }
  }

  public stop() {
    this.stopTranscription()
    this.iatWS?.close()
    this.iatWS = undefined
    this.recorder?.stop()
    this.recorder?.destroy()
    this.recorder = null
    this.stream?.getTracks().forEach((track) => track.stop())
    this.stream = null
    interval && clearInterval(interval)
  }

  public onTextResonse(callback: (text: string, state: asrState, closed?: boolean) => void) {
    this._onTextResonse = callback
    if (!this.iatWS || this.iatWS.readyState === WebSocket.CLOSED) {
      this.connectWebSocket()
    }
  }

  private _speakStateCallBack: (isSpeaking: 'speaking' | 'ended') => void = (isSpeaking) => {
    console.log('isSpeaking', isSpeaking)
  }
  public subscribeVolume(callback: (isSpeaking: 'speaking' | 'ended') => void) {
    this._speakStateCallBack = callback
  }

  public onWujiStreamCompleted(callback: (stream: MediaStream) => void) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.onWujiStreamCompleted = (stream: MediaStream) => {
      // console.warn("onWujiStreamVolumeChange", instant);
      this.stream = stream
      callback(stream)
    }
  }

  private _paused = false
  public isPaused() {
    return this._paused
  }
  public pause() {
    this._paused = true
  }
  public continue() {
    this._paused = false
  }
}
